import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CategoryTitle from '../../components/categories/Category-title'
import CategoryListSecondLevel from '../../components/categories/Categories-list-second-level'
import Topbar from '../../components/sections/Topbar';

export default ({ data: { category } }) => (
  <Layout>
    <CategoryTitle 
      backgroundUrl={category.categoryImage.headerBackground.localFile.childImageSharp.fluid} 
      title={category.name}
    />
    <div className="mx-auto container px-4">
      <CategoryListSecondLevel categories={category} />
    </div>
  </Layout>
)


export const query = graphql`
    query($slug: String!) {
      category: wpCategory(slug: {eq: $slug}) {
        name
        slug
        termTaxonomyId
        products {
          nodes {
            id
            title
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        wpChildren {
          nodes {
            name
            products {
              nodes {
                id
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        fluid(fit: CONTAIN, maxWidth: 300, quality: 50) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        categoryImage {
          headerBackground {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
`