import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import ProductItem from '../products/ProductListingItem'

const CategoryListSecondLevel = ( {categories} ) => {

  let productList

  if (categories.wpChildren.nodes.length === 0) {
    productList = <div className="flex flex-wrap">
      {categories.products.nodes && categories.products.nodes.map((product) => (
        <ProductItem key={uuidv4()} product={product} />
      ))} 
    </div>
  } else {
    productList = categories.wpChildren.nodes.map(category => (
        <div className="w-full mb-10 md:mb-20" key={uuidv4()}>
          {category.name && <h3 className="w-full text-3xl px-3">{category.name}</h3> }
          <div className="flex flex-wrap">
            {category.products.nodes && category.products.nodes.map((product) => (
              <ProductItem key={uuidv4()} product={product} />
            ))} 
          </div>
        </div>
      ))
  }

  return (
    <div className="mb-16 md:mb-24">
      {productList}
    </div>
  )
}

export default CategoryListSecondLevel
